<template>
  <div class="login-page">
    <div class="image-container">
      <div class="rectangle_images">
        <img src="../../images/rectangle.png" alt="rectangles"/>
      </div>
      <img src="../../images/tri-new.svg" alt="Login Image" class="logo"/>
    </div>
    <div class="animated-img">
      <img src="../../images/animated_img.png" alt="Image" />
    </div>

    <div :class="['form-container', transitionClass, { 'slide-up': animateForm }]">
      <!-- <template v-if="currentStage === 'cards'">
        <div class="cards-container">
          <div
            class="card"
            :class="{ 'selected-card': selectedCard === 'solarPro' }"
            @click="selectCard('solarPro')"
          >
            <img
              src="../../images/log_sol.png"
              alt="Card Image 1"
              class="card-image"
              style="filter: sepia(1) invert(1) hue-rotate(-80deg)"
            />
            <div class="card-content">
              <p class="card-text">Solar Pro</p>
              <p class="card-subtext">
                Tailored solar performance management with AI-driven
                optimization.
              </p>
            </div>
          </div>
          <div
            class="card"
            :class="{ 'selected-card': selectedCard === 'energyTrading' }"
            @click="selectCard('energyTrading')"
            style="filter: grayscale(20%); opacity: 0.8"
          >
            <img
              src="../../images/trade.png"
              alt="Card Image 2"
              class="card-image"
            />
            <div class="card-content">
              <p class="card-text">Energy Trading</p>
              <p class="card-subtext">Peer-to-Peer Solar Energy Trading.</p>
            </div>
          </div>
          <button @click="proceedToNextStage" :disabled="!selectedCard">
            Proceed
          </button>
          <div class="privacy-policies">
            <div class="privacy">Privacy Policy</div>
            <div>|</div>
            <div class="terms">Terms & Conditions</div>
          </div>
        </div>
      </template> 

      <template v-if="currentStage === 'discom'">
        <div class="form-group">
          <label for="discom" class="name">Choose DISCOM</label>
          <div class="dropdown-container">
            <select id="discom" v-model="selectedDiscom">
              <option value="" disabled selected>Select DISCOM</option>
              <option value="discom1">DISCOM 1</option>
              <option value="discom2">DISCOM 2</option>
              <option value="discom3">DISCOM 3</option>
            </select>
            <img
              src="../../images/buy_down.png"
              alt="Dropdown Arrow"
              class="dropdown-arrow"
            />
          </div>
          <label for="caNumber" class="name">Enter CA Number</label>
          <input
            type="text"
            id="caNumber"
            v-model="caNumber"
            placeholder="CA Number"
            required
          />
          <button @click="proceedToPhone">Next</button>
          <div class="privacy-policies">
            <div class="privacy">Privacy Policy</div>
            <div>|</div>
            <div class="terms">Terms & Conditions</div>
          </div>
        </div>
      </template> -->

      <template v-if="currentStage === 'phone'">
        <div class="form-group">
          <label for="phoneNumber" class="phone-number"
          >Enter phone number to proceed.</label
          >
          <p class="mobile">10 digit mobile number</p>
          <input
              type="number"
              id="phoneNumber"
              v-model="phoneNumber"
              placeholder="Enter mobile number"
              required
          />
          <p v-if="error" class="error-msg">{{ error }}</p>
          <button @click="handleSendOtp" :disabled="isLoading">
            <div v-if="!isLoading">Continue</div>
            <div v-else class="spinner-border spinner-border-md" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
          <div class="privacy-policies">
            <div class="privacy">Privacy Policy</div>
            <div>|</div>
            <div class="terms">Terms & Conditions</div>
          </div>
        </div>
      </template>

      <template v-else-if="currentStage === 'otp'">
        <div class="form-group otp_verification">
          <label for="otp" class="verification">VERIFICATION</label>
          <p>OTP is sent to your mobile, please check.</p>
          <p>Enter 6 digit PIN</p>
          <div class="otp-container" @paste="handlePaste">
            <input
                type="text"
                v-for="(digit, index) in otpDigits"
                :key="index"
                v-model="otpDigits[index]"
                maxlength="1"
                @input="moveToNext(index)"
                :ref="(el) => (otpRefs[index] = el)"
                @keydown="handleOnKeyPress($event, index)"
                required
                inputmode="numeric"
                pattern="[0-9]*"
                :id="'otp-input-' + index"
            />
          </div>
          <p v-if="error" class="error-msg">{{ error }}</p>
          <p v-if="isOtpSentAgain" class="success-msg">Otp sent again!</p>
          <button @click="handleVerifyOtp" :disabled="isLoading">
            <div v-if="!isLoading">Verify OTP</div>
            <div v-else class="spinner-border spinner-border-md" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
          <div class="resend">
            Didn't receive it? <span @click="resendOTP">Resend it</span>
          </div>
        </div>
      </template>

      <template v-else-if="currentStage === 'details'">
        <div class="form-group">
          <p class="phone-number">Enter Details</p>
          <label for="firstName" class="name">Enter First Name</label>
          <input
              type="text"
              id="firstName"
              v-model="firstName"
              placeholder="First Name"
              required
          />
          <label for="lastName" class="name last-name-label"
          >Enter Last Name</label
          >
          <input
              type="text"
              id="lastName"
              v-model="lastName"
              placeholder="Last Name"
              class="last-name-input"
              required
          />
          <router-link to="/address">
            <button>Submit</button>
          </router-link>
          <div class="privacy-policies">
            <div class="privacy">Privacy Policy</div>
            <div>|</div>
            <div class="terms">Terms & Conditions</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import router from "@/router";
import AuthService from "../services/auth.service";
import LoginService from "../services/login.service";
import UserService from "@/services/user.service";

export default {
  data() {
    return {
      currentStage: 'phone',
      phoneNumber: '',
      otpDigits: ['', '', '', '', '', ''],
      otpRefs: [],
      firstName: '',
      lastName: '',
      selectedDiscom: '',
      caNumber: '',
      selectedCard: 'solarPro',
      animateForm: false,
      transitionClass: "transition-form",
      error: "",
      isLoading: false,
      ctx: "",
      isOtpSent: false,
      isOtpSentAgain: false,
      counter: 30,
    };
  },

  mounted() {
    setTimeout(() => {
      this.animateForm = true;
    }, 100);
    if (AuthService.isLoggedIn()) {
      return router.push("/homepage"); // Redirect to the Home page if already logged in
    }
    setTimeout(() => {
      document.getElementById('phoneNumber').focus();
    }, 400);
  },
  methods: {
    ...mapActions(["selectCard"]),
    selectCard(card) {
      this.selectedCard = card;
      localStorage.setItem("selectedCard", card);
      this.$store.dispatch("updateSelectedCard", card);
    },
    proceedToNextStage() {
      if (this.selectedCard === "solarPro") {
        this.currentStage = "phone";
      } else if (this.selectedCard === "energyTrading") {
        this.currentStage = "discom";
        this.$emit("update-bottom-nav", true);
      } else {
        this.currentStage = "discom";
        this.$emit("update-bottom-nav", false);
      }
      this.triggerAnimation();
    },
    proceedToPhone() {
      this.currentStage = "phone";
      this.triggerAnimation();
    },
    validateMobile(mobile) {
      const regex = /^(10[0-9]01)?[6-9]\d{9}$/; // Indian mobile number validation (10 digits, starting with 6-9)
      return regex.test(mobile);
    },
    async handleSendOtp(e) {
      e.preventDefault();
      this.isLoading = true;
      if (!this.validateMobile(this.phoneNumber)) {
        this.error = "Please enter a valid mobile number!";
        this.isLoading = false;
        return; // Don't call the API if mobile number is not valid
      }
      this.error = "";
      try {
        const response = await LoginService.sendOtp(this.phoneNumber);
        if (response && response.success && response.ctx) {
          this.ctx = response.ctx;
          this.isOtpSent = true;
        } else {
          this.error = "Something went wrong";
        }
        this.isOtpSent = true;
        this.currentStage = "otp";
        setTimeout(() => {
          document.getElementById('otp-input-0').focus();
        }, 200);
        this.triggerAnimation();
        this.counter = 30;
      } catch (error) {
        this.isLoading = false;
        this.error = error.message.split(":")[1].trim();
      }
      this.isLoading = false;
    },
    validateOtp() {
      const otpString = this.otpDigits.join("");
      if (otpString.length < 6) return false;
      const otpNumber = parseInt(otpString, 10);
      return !isNaN(otpNumber);
    },
    async handleVerifyOtp(e) {
      e.preventDefault();
      this.isLoading = true;
      if (!this.validateOtp()) {
        this.error = "Enter a valid OTP.";
        this.isLoading = false;
        return;
      }
      const otpString = this.otpDigits.join("");
      try {
        const response = await LoginService.verifyOtp(otpString, this.ctx);
        if (response && response.success) {
          localStorage.setItem("_token", response.token);
          localStorage.setItem("_id", response.id);
          const currentUserNode = await UserService.getUserNodeDetail();
          this.$store.dispatch("updateCurrentUserNode", currentUserNode[0]);
          router.push("/homepage");
        } else {
          this.error = "Please enter valid OTP. Please try again.";
        }
      } catch (err) {
        this.isLoading = false;
        this.error = err.message.split(":")[1].trim();
      }
      this.isLoading = false;
    },
    handlePaste(e) {
      const pasteData = e.clipboardData.getData("Text").slice(0, 6);
      const newDigits = [...this.otpDigits];
      for (let i = 0; i < pasteData.length; i++) {
        newDigits[i] = pasteData[i];
        if (this.otpRefs[i].current) this.otpRefs[i].focus();
      }
      this.otpDigits = newDigits;
    },

    triggerAnimation() {
      this.animateForm = false;
      setTimeout(() => {
        this.animateForm = true;
      }, 200);
    },
    moveToNext(index) {
      // Move focus to the next input if the current input has exactly 1 digit and it's not the last one
      if (
          this.otpDigits[index].length === 1 &&
          index < this.otpDigits.length - 1
      ) {
        // Access the next input in the array of refs
        this.otpRefs[index + 1].focus(); // Use the stored reference to focus the next input
      }
    },

    handleOnKeyPress(event, index) {
      const key = event.key;
      if (key === "Backspace") {
        if (this.otpDigits[index] === "") {
          // Move focus to the previous input if it exists and clear the previous digit
          if (index > 0) {
            this.otpRefs[index - 1].focus();
            const newDigits = [...this.otpDigits];
            newDigits[index - 1] = ""; // Clear the previous input's value
            this.otpDigits = newDigits;
          }
        } else {
          // Clear the current digit
          const newDigits = [...this.otpDigits];
          newDigits[index] = "";
          this.otpDigits = newDigits;
        }
      }
    },
    async resendOTP(e) {
      await this.handleSendOtp(e);
      this.isOtpSentAgain = true;
      setTimeout(() => {
        this.isOtpSentAgain = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

.login-page {
  max-width: 100%;
  margin: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to right, #050011, #120b41);
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.rectangle_images {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.rectangle_images img {
  height: 220px;
  width: 375px;
}

.image-container {
  position: absolute;
  text-align: center;
  margin-bottom: -56px;
  z-index: 0;
  top: 0;
  width: 100%;
}

.image-container .logo {
  position: relative;
  margin-top: 26px;
  margin-bottom: 25px;
  z-index: 3;
  height: 20px;
}

.animated-img {
  position: relative;
  z-index: 5;
  height: calc(100vh - 155px);
  padding: 110px 30px 30px 30px;
  box-sizing: border-box;
  width: calc(100% - 100px);
  margin: auto;

  img{
    max-width: 100%;
  }
}

.form-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0px;
  text-align: center;
  background-color: #ffffff;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  width: 100%;
  transform: translateY(100%);
  animation: slide-up-animation 0.5s forwards;
  z-index: 10;
}

@keyframes slide-up-animation {
  to {
    transform: translateY(0);
  }
}

.form-group {
  text-align: left;
  padding: 20px 20px;
}

.error-msg {
  color: red;
  font-size: 12px;
  padding-top: 0;
}

.success-msg {
  color: green;
  font-size: 12px;
  padding-top: 0;
}

@media (min-width: 568px) {
  .form-group {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .form-group {
    padding: 40px;
  }
}

.form-group.last_name {
  margin-top: -36px !important;
}

.form-group.otp_verification p {
  font-size: 12px;
}

.form-group p {
  font-size: 14px;
  font-family: "Montserrat";
}

@media (max-width: 568px) {
  .form-group p {
    font-size: 12px;
  }
}

input {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

select {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  appearance: none;
  background-color: white;
  background-image: none;
  color: #000000;
}

.dropdown-container {
  position: relative;
}

.dropdown-arrow {
  position: absolute;
  right: 12px;
  top: 26%;
  height: 9px;
}

button {
  padding: 7px 20px;
  background-color: #453a94;
  color: white;
  border: none;
  border-radius: 4px;
  width: 100%;
  font-size: 18px;
}


.privacy-policies {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: "Montserrat";
  color: #808080;
  font-size: 12px;
}

.privacy {
  padding: 5px;
}

.terms {
  padding: 5px;
}

.mobile {
  margin-top: 18px;
  margin-bottom: 20px;
  font-family: "Montserrat";
  font-size: 12px;
}

.phone-number {
  font-size: 12px;
  font-family: "Montserrat";
  font-weight: 600;
  text-transform: uppercase;
}

.name {
  font-family: "Montserrat";
  font-size: 12px;
}

.resend {
  margin-top: 40px;
  text-align: center;
  font-size: 12px;
  font-family: "Montserrat";
}

.resend span {
  color: #453a94;
  cursor: pointer;
}

.otp-container {
  display: flex;
  gap: 10px;
}

.otp-container input {
  width: 20%;
  text-align: center;
  font-size: 24px;
}

.cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px 18px;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 9px 30px;
  margin: 10px 0;
  width: 100%;
  height: 97px;
  gap: 30px;
  box-sizing: border-box;
  text-align: left;
  background-color: #efefef;
}

.selected-card {
  background-color: #fff;
  color: white;
}

.card-content {
  display: flex;
  flex-direction: column;
}

.card-text {
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 700;
  color: #000000;
  margin: 0;
}

.card-subtext {
  font-size: 12px;
  font-family: "Montserrat";
  color: #000000;
  margin: 0;
}

.transition-form {
  transition: bottom 0.9s;
}

.verification {
  font-size: 14px;
  font-family: "Montserrat";
  color: #453a94;
  font-weight: 700;
}
</style>
