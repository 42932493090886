import { createStore } from 'vuex';

const store = createStore({
  state: {
    selectedCard: localStorage.getItem('selectedCard') || '',
    currentUserNodeDetail: JSON.parse(localStorage.getItem('currentUserNodeDetail')) || null,
    cohortIds: JSON.parse(localStorage.getItem('cohortIds')) || [],
    currentUserNode: JSON.parse(localStorage.getItem('currentUserNode')) || null,
    isMultiSite: JSON.parse(localStorage.getItem('isMultiSite')) || false
  },
  mutations: {
    setSelectedCard(state, card) {
      state.selectedCard = card;
      localStorage.setItem('selectedCard', card);
    },
    setCurrentUserNodeDetail(state, nodeDetail) {
      state.currentUserNodeDetail = nodeDetail;
      localStorage.setItem('currentUserNodeDetail', JSON.stringify(nodeDetail));
    },
    setCohortIds(state, cohortIds) { 
      state.cohortIds = cohortIds;
      localStorage.setItem('cohortIds', JSON.stringify(cohortIds));
    },
    setCurrentUserNode(state, nodeDetail) {
      state.currentUserNode = nodeDetail;
      localStorage.setItem('currentUserNode', JSON.stringify(nodeDetail));
    },
    setIsMultiSite(state, isMultiSite) {
      state.isMultiSite = isMultiSite;
      localStorage.setItem('isMultiSite', isMultiSite);
    }
  },
  actions: {
    updateSelectedCard({ commit }, card) {
      commit('setSelectedCard', card);
    },
    updateCurrentUserNodeDetail({ commit }, nodeDetail) {
      commit('setCurrentUserNodeDetail', nodeDetail);
    },
    updateCohortIds({ commit }, cohortIds) { 
      commit('setCohortIds', cohortIds);
    },
    updateCurrentUserNode({ commit }, nodeDetail) {
      commit('setCurrentUserNode', nodeDetail);
    },
    updateIsMultiSite({ commit }, isMultiSite) {
      commit('setIsMultiSite', isMultiSite);
    }
  },
  getters: {
    selectedCard: (state) => state.selectedCard,
    currentUserNodeDetail: (state) => state.currentUserNodeDetail,
    cohortIds: (state) => state.cohortIds,
    currentUserNode: (state) => state.currentUserNode,
    isMultiSite: (state) => state.isMultiSite
  }
});

export default store;
