<template>
  <div class="list_container">
    <div class="site_list_card-container">
      <div
        class="site_list_card"
        v-for="(card, index) in mappedCards"
        :key="index"
        :style="{ borderLeftColor: cardConfig[card.type].borderColor }"
        @click="handleSiteSelected(index)"
      >
        <div class="site_list_card-left">
          <div class="site_list_header">
            <div
              class="site_list_image-box"
              :style="{
                backgroundColor: cardConfig[card.type].backgroundColor,
              }"
            >
              <img :src="cardConfig[card.type].icon" alt="Card Icon" />
            </div>
            <p class="site_list_title">{{ card.title }}</p>
          </div>
          <p class="site_list_description">{{ card.address }}</p>
          <div class="site_list_icons-section">
            <div class="site_list_icon-wrapper">
              <div class="parent-container">
                <div
                  class="child-container"
                  v-for="(item, idx) in filteredDetails(card.details)"
                  :key="idx"
                >
                  <div
                    class="icon-container"
                    :style="{ backgroundColor: colors[idx % colors.length] }"
                  >
                    <img src="../../images/list_icon.jpg" alt="Detail Icon" />
                  </div>
                  <p class="text-left">{{ item.label }}</p>
                  <p class="text-right">{{ item.value }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="loader" v-if="hasMoreNodes" ref="loader">Loading Sites...</div>
    </div>
  </div>
</template>

<script>
import residentialIcon from "../../images/resedential.png";
// import commercialIcon from "../../images/commercial.svg";
// import solarIcon from "../../images/solarw.svg";
import DataService from "@/services/data.service";
import UserService from "@/services/user.service";

export default {
  name: "SiteListing",
  data() {
    return {
      colors: [
        "#453A94",
        "#0ACE17",
        "#FFB215",
        "#E12F13",
        "#18B0EF",
        "#C28855",
      ],
      cardConfig: {
        residential: {
          borderColor: "#13b78c",
          backgroundColor: "#13b78c",
          icon: residentialIcon,
        },
        commercial: {
          borderColor: "#453A94",
          backgroundColor: "#453A94",
          // icon: commercialIcon,
        },
        solar: {
          borderColor: "#FFB92A",
          backgroundColor: "#FFB92A",
          // icon: solarIcon,
        },
      },
      offset: 0,
      limit: 10,
      nodeList: [],
      observer: null,
      isLoading: false,
      hasMoreNodes: true,
    };
  },
  async mounted() {
    await this.fetchNodeDetails();
    this.setupObserver();
  },
  updated() {
    this.setupObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  computed: {
    mappedCards() {
      return this.nodeList.map((node) => {
        const address = `${node.address?.line1}`;
        const inverters = node.hardwareGroups.reduce(
          (total, group) => total + group.devices.length,
          0
        );

        const devices = node.hardwareGroups.flatMap((group) =>
          group.devices.map((device) => ({
            type: device.hardwareType.typeName,
            model: device.hardwareType.model.str,
            brand: device.hardwareType.brand.name.str,
            category: device.hardwareType.category.name.str,
            uid: device.uid,
            imei:
              device.props.find((prop) => prop.prop === "imei")?.val || "N/A",
            simNumber:
              device.props.find((prop) => prop.prop === "simNumber")?.val ||
              "N/A",
            size:
              device.hardwareType.props.find((prop) => prop.prop === "size")
                ?.val || "N/A",
            slaveId:
              device.hardwareType.props.find((prop) => prop.prop === "slaveId")
                ?.val || "N/A",
          }))
        );

        return {
          type: "residential",
          title: node.name.str,
          address: address,
          details: [
            { label: "Inverter :", value: inverters.toString() },
            { label: "EV :", value: "0" },
            { label: "Meter :", value: "0" },
          ],
          devices: devices,
        };
      });
    },
  },
  methods: {
    handleSiteSelected(cardIdx) {
      this.$emit("site-selected", this.nodeList[cardIdx]);
    },
    filteredDetails(details) {
      return details.filter((item) =>
        ["Inverter :", "EV :", "Meter :"].includes(item.label)
      );
    },
    async fetchNodeDetails() {
      if (this.isLoading) return;
      this.isLoading = true;
      const cohortIds = this.$store.getters.cohortIds;
      const cohortNodes = await DataService.listNodeDetail(cohortIds, this.offset, this.limit);

      if (this.offset === 0) {
        const userNodes = await UserService.getUserNodeDetail();
        const mergedNodes = [...userNodes, ...cohortNodes].reduce((acc, node) => {
          acc.set(node.id, node);
          return acc;
        }, new Map());
        this.nodeList = Array.from(mergedNodes.values());
      } else {
        this.nodeList = [...this.nodeList, ...cohortNodes];
      }

      this.offset += this.limit;
      this.isLoading = false;

      // Check if there are more nodes to load
      this.hasMoreNodes = cohortNodes.length === this.limit;
    },
    handleIntersect(entries) {
      if (entries[0].isIntersecting && this.hasMoreNodes) {
        this.fetchNodeDetails();
      }
    },
    setupObserver() {
      const loaderElement = this.$refs.loader;
      if (loaderElement) {
        if (this.observer) {
          this.observer.disconnect();
        }
        this.observer = new IntersectionObserver(this.handleIntersect);
        this.observer.observe(loaderElement);
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.site_list_card-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10px;
  margin-top: 50px;
}
.site_list_card {
  display: flex;
  gap: 1rem;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  border-left: 8px solid #13b78c;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.site_list_card-left {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 10;
}
.site_list_header {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.site_list_image-box {
  background-color: #13b78c;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 73px;
  height: 73px;
  border-radius: 10px;
}
.site_list_image-box img {
  max-width: 80%;
  max-height: 80%;
}
.site_list_title {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  font-family: "Montserrat", serif;
  text-align: left;
}

.site_list_description {
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin: 0;
  font-family: "Montserrat", serif;
  text-align: left;
}

.site_list_icons-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.site_list_icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

.parent-container {
  display: flex;
  gap: 0.5rem;
  font-family: "Montserrat", serif;
}

.child-container {
  display: flex;
  gap: 5px;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
}
.icon-container img {
  height: 15px;
}

.text-left {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.text-right {
  font-size: 13px;
  color: #000;
  font-weight: 500;
}

.loader {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #453A94;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader::after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  margin: 10px auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #453A94;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .site_list_card {
    flex-direction: column;
    gap: 1rem;
  }

  .site_list_card-left {
    flex-direction: column;
  }

  .site_list_image-box {
    width: 50px;
    height: 50px;
  }

  .site_list_title {
    font-size: 16px;
  }

  .site_list_description {
    font-size: 14px;
  }

  .site_list_icons-section {
    gap: 0.5rem;
  }

  .text-left,
  .text-right {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .site_list_image-box {
    width: 40px;
    height: 40px;
  }

  .site_list_title {
    font-size: 12px;
  }

  .site_list_description {
    font-size: 10px;
  }

  .site_list_icons-section {
    gap: 0.5rem;
  }

  .text-left,
  .text-right {
    font-size: 10px;
  }
}
</style>
