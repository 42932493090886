import ApiCoreService from "./api.core.service";

export default class UserService extends ApiCoreService {
  static get token() {
    const token = localStorage.getItem("_token"); // Fetch from localStorage
    return token ? token : null;
  }

  static get userId() {
    const userId = localStorage.getItem("_id"); // Fetch from localStorage
    return userId ? userId : null;
  }

  static async getWalletBalance() {
    try {
      return await this.graphCall(
        "balance",
        `
           {
  withAuth(token: "auto"){
    listWallet(criteria: "{\\"user\\":\\"${this.userId}\\"}") {
      id
      transactions{ts amount desc}
    }
  }
}

mapper<safejs-

data.balance = 0;
if(data.total > 0){
  data.balance = data.withAuth.listWallet[0].transactions.reduce((p,c) => p+c.amount , 0);
} 
-js>
            `,
        {}
      );
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  static async getWalletTransactions() {
    try {
      return await this.graphCall(
        "transactions",
        `
           {
  withAuth(token: "auto"){
    listWallet(criteria: "{\\"user\\":\\"${this.userId}\\"}") {
      id
      transactions{ts amount desc}
    }
  }
}

mapper<safejs-

data.transactions = [];
if(data.total > 0){
  data.transactions = data.withAuth.listWallet[0].transactions;
} 
-js>
            `,
        {}
      );
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  static async getCurrentUser() {
    try {
      let user;
      if (localStorage.getItem("tuser")) {
        user = JSON.parse(localStorage.getItem("tuser"));
      } else {
        user = await this.graphCall(
          "withAuth.getUser",
          `{
 withAuth(token: "${this.token}"){
                    getUser(id:"${this.userId}"){
                      id
                      name {
                         str
                      }
                      address{careOf line1 line2 line3 city state pin country{name{str}}}
                      gender
                      emails {
                        address     
                      }
                      phones {
                        number                    
                      }
                      status
                      picture{
                        uri 
                      }
                      props {
                          prop
                          val
                          propType
                      }
                      defaultRole{name{str}}
                       address{
                        line1
                        line2
                      }
                    olc{
                      deny{
                        resource
                        condition
                      }
                      allow{
                        resource
                        condition
                      }
                    }
                   organizations{
                    org{
                      olc{
                        deny{
                          resource
                          condition
                        }
                        allow{
                          resource
                          condition
                        }
                      }
                    }
                  }
                    }
            } 
}`
        );
        localStorage.setItem("tuser", JSON.stringify(user));
      }
      return user;
    } catch (c) {
      throw new Error(c);
    }
  }

  static async getCurrentUserNode() {
    try {
      return await this.graphCall(
        "withAuth.listP2PMeter",
        `{
  withAuth(token: "${this.token}"){
    
    listP2PMeter(criteria: "{\\"user\\":\\"[[me]]\\"}"){
      id
      no
      user{id name{str} props{prop val propType}}
      meterType
      averageDailyProduction
      averageDailyExport
      averageDailyConsumption
      averageBuyPrice
      averageSellPrice
      boundDiscomCode
      ratePerUnit
      props{prop val propType}
      node{
        id name{str} cohorts{id name{str} props{prop val propType}}
      organizations{id addresses{careOf line1 line2 line3 city state pin country{name{str}}}}
      contactName{str}
      nodeType{id name{str} code}
      props{prop val propType}
      hardwareGroups{
        id name{str} description{str} groupType{id name{str} code description{str}}  devices{
          id connectedTo{id} uid hardwareType{ id typeName code model{str} description{str} 
            brand{
              id name{str} code description{str}
            }
            category{id name{str} description{str} code}
            props{prop val propType}
          }
        }
      }
    }
      }
    
  }
}
`
      );
    } catch (c) {
      throw new Error(c);
    }
  }

  static async getUserNodeDetail() {
    try {
      return await this.graphCall(
        "withAuth.listNode",
        `{
     withAuth(token: "${this.token}"){
           listNode(criteria: "{\\"code\\":\\"${this.userId}\\"}"){
           id
        name{
          str
        }
        address{
        careOf line1 line2 line3 city state pin 
        country{
        name{str}
        }
        }
        code
        contactName{
          str
        }
        hardwareGroups{
          id
          groupType{id name{str} code description{str}} 
          devices{
            id
            uid
            hardwareType{
              id typeName code model{str} description{str} 
                brand{
                            id name{str} code description{str}
                          }
                          category{id name{str} description{str} code}
                          props{prop val propType}
            }
            props{
              prop
              val
              propType
            }
          }
        }
        
      }  
  }
  }`
      );
    } catch (c) {
      throw new Error(c);
    }
  }

  static async getNodeDetail(cohortIds, offset, limit) {
    let criteria = JSON.stringify({ cohorts: { $in: cohortIds } });

    try {
      return await this.graphCall(
        "withAuth.listNode",
        `{
  withAuth(token: "auto") {
    listNode(criteria: ${JSON.stringify(
      criteria
    )} limit:${limit} offset:${offset}) {
        id
      name {
        str
      }
        address{
        careOf line1 line2 line3 city state pin 
        country{
        name{str}
        }
        }    
      cohorts {
        id
      }
      code
      contactName {
        str
      }
      hardwareGroups {
        id
        groupType {
          id
          name {
            str
          }
          code
          description {
            str
          }
        }
        devices {
          id
          uid
          hardwareType {
            id
            typeName
            code
            model {
              str
            }
            description {
              str
            }
            brand {
              id
              name {
                str
              }
              code
              description {
                str
              }
            }
            category {
              id
              name {
                str
              }
              description {
                str
              }
              code
            }
            props {
              prop
              val
              propType
            }
          }
          props {
            prop
            val
            propType
          }
        }
      }
    }
  }
}`
      );
    } catch (c) {
      console.log("Error in getNodeDetail", c);
      throw new Error(c);
    }
  }

  static async getSalesForTheDay() {
    try {
      return await this.graphCall(
        "withAuth.listP2PListing",
        `{
  withAuth(token: "${this.token}"){
    listP2PListing(criteria:"{\\"type\\": \\"SELL\\", \\"status\\": {\\"$ne\\": \\"UNCLAIMED\\"},\\"seller\\": \\"[[me]]\\"}" limit:10000){
      id
      type
      units
      amount
      startDate
      endTDate
      status
      actualTraded
      moneyToSeller
      moneyToTrustLayer
      moneyToDiscom
      moneyToBap
      moneyToBpp
      buyerOrg{id name{str}}
      counterNode{id code name{str}}
      actualTraded
      seller{id addresses{careOf line1 line2 line3 city state country{id code} pin} name{str}}
      user{id name{str} address{careOf line1 line2 line3 city state pin country{id name{str} code}}}
    }
    
  }
}
`
      );
    } catch (c) {
      throw new Error(c);
    }
  }
}
