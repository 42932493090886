<template>
  <div class="status_container">
<p class="status_top">Status</p>
<div class="status">
  <div class="status-item">
    <p class="units_today">{{ tg ? tg : 0 }} <span v-if="tg">{{ tgUnit ? tgUnit : '' }}</span></p>
    <p class="today">Today</p>
    <div class="border-line"></div>
  </div>
  <div class="status-item">
    <p class="units_today">{{ yg ? yg : 0 }} <span v-if="yg">{{ ygUnit ? ygUnit : '' }}</span></p>
    <p class="today">Yesterday</p>
    <div class="border-line"></div>
  </div>
  <div class="status-item">
    <p class="units_today">{{ atg ? atg : 0 }} <span v-if="atg">{{ totalGenerationUnit ? totalGenerationUnit : '' }}</span></p>
    <p class="today">{{totalGenerationLabel ? totalGenerationLabel : 'This month'}}</p>
    <div class="border-line"></div>
  </div>
</div>
</div>
</template>

<script>
import DataService from '@/services/data.service';
import UserService from '@/services/user.service';
import changeUnitUtils from '@/utils/changeUnitUtils';

export default {
  name:'Status',
  data() {
    return {
      tg: 0,
      yg: 0,
      atg: 0,
      ats: 0,
      tgUnit: 'kWh',
      ygUnit: 'kWh',
      savingSuffix: '',
      capacity: 0,
      temperature: 0,
      totalGenerationLabel:'This month',
      totalGenerationUnit:'kWh',
      date: "",
      userName:"App User",
      user:null,
      nameInitials: "",
      loaded:false,
      imei:"",
    };
  },
  async mounted() {
    try {
      await this.extractImei();
      console.log(this.imei,"logging imei in statuses")
      const result=await DataService.getData(this.imei);
      if(result){
      this.loaded=true;
      [this.tg,this.tgUnit] =changeUnitUtils.getUnitAndVal(result.todayTotal);
      [this.yg,this.ygUnit]= changeUnitUtils.getUnitAndVal(result.yesterdayTotal);
      [this.atg,this.totalGenerationUnit]=changeUnitUtils.getUnitAndVal(result.totalGenerationTracked);
      this.ats =this.getTotalSavings(result.totalSavings);
      this.date = result.dateToday;
      this.temperature=result.temperature;
      this.capacity=result.plantSize;
      this.totalGenerationLabel=result.totalGenerationLabel;
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    goToSlide(index) {
      this.currentSlide = index;
    },

    getTotalGeneration(totalGenerationTracked,totalGenerationLabel){
       if(totalGenerationLabel=='All Time' && totalGenerationTracked<1000){
         this.totalGenerationUnit='kWh';
         return totalGenerationTracked.toFixed(0);
       }
       else if(totalGenerationLabel=='All Time' && totalGenerationTracked>1000 && totalGenerationTracked<1000000){
         this.totalGenerationUnit='MWh';
         return (totalGenerationTracked/1000).toFixed(2);
       }else if(totalGenerationLabel=='All Time' && totalGenerationTracked>1000000){
        this.totalGenerationUnit='GWh';
        return (totalGenerationTracked/1000000).toFixed(2);
       }
       else{
        this.totalGenerationUnit='kWh';
        return totalGenerationTracked.toFixed(0);
       }


    },
    getTotalSavings(totalSavings){
      if(totalSavings<1000){
        return totalSavings.toFixed(0);
      }else if(totalSavings>1000 && totalSavings<1000000){
        this.savingSuffix='k';
        return (totalSavings/1000).toFixed(2);
      }else if(totalSavings>1000000){
        this.savingSuffix='m';
        return (totalSavings/1000000).toFixed(2);
      }
    },
    async extractImei() {
      const data = this.$store.getters.currentUserNode;
      if (data && data.hardwareGroups) {
       const imei= data.hardwareGroups[0].devices[0].props.find((el) => el.prop === "imei")?.val || "";
       if(imei){
        this.imei = imei;
        return;
       }
       this.imei='';      }
    },  
  },
};
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');




.status {
  display: flex;
  justify-content: space-between;
  padding: 17px 2px;
  background: linear-gradient(to right, #050011, #120B41);
  background: linear-gradient(-42.979deg, #1D0C47 0%, #050011 100%);
  border-radius: 20px;
}

.status_top {
  font-size: 15px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    padding: 0 10px;
    margin: 10px 0 10px;

}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px 0;
  text-align: center;
  width: calc(100% / 3);
}

.border-line {
  position: absolute;
  width: 1px;
  height: 100%;
  background: linear-gradient(-42.979deg, #7F74D2 100%, #D6D1FF 100%);
  right: 0;
  top: 0;
  transform: translateX(50%);
}

.status-item:last-child .border-line {
  display: none;
}

.units_today {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  color: #00FFBC;
  font-weight: 500;
  margin: 0;
}

.units_today span {
  font-weight: 400;
  line-height: 30px;
  padding-left: 5px;
  font-size: 10px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}

.today {
  font-size: 10px;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}




</style>